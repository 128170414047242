import React from 'react'
import Navbar from './Navbar'
import styled from 'styled-components'

export default function Layout({ children }) {
    const Container = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 120px;
       // z-index: 1;
    `

    const Layout = styled.div`
        position: relative;
        width: 100%;
    `

    const NavBarWrap = styled.div`
        position: fixed;
        width: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    `
    return (
        <Layout>
            <NavBarWrap>
                <Navbar />
            </NavBarWrap>
            <Container>{children}</Container>
        </Layout>
    )
}
