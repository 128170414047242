import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { devices } from '../styles/devices'
import '../styles/global.scss'
import SubscribeModal from '../components/SubscribeModal'

export default function Navbar() {
    const [isToggle, setIsToggle] = useState(false)

    const toggleMenu = () => {
        setIsToggle(!isToggle)
    }

    const fadeDown = keyframes`
        from {
            opacity: 0;
            transform: translateY(-100px);
        }
        to {
            opacity: 1;
            transform: translateY(px);
        }
    `

    const NavBar = styled.div`
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 35px -20px rgba(0,0,0,0.4);
        background: #fff;
        z-index: 99;
        transition: all 1s ease;

        @media (max-width: ${devices.lg}) {
            flex-direction: column;
            min-height: 80px;
            height: 100%;
        }
    `

    const Contents = styled.div`
        margin: 1rem 0rem;
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `

    const Link = styled.a`
        padding: 0.75rem 1rem;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        color: #000;

        @media (max-width: ${devices.lg}) {
            display: none;
        }
    `

    const ItemWrap = styled.div`
      display: flex;
      justify-content: space-evenly,
      align-items: center
      height: 100%
    `

    const Button = styled.button`
        padding: 0.75rem 1.5rem;
        border: 1px #000 solid;
        color: #000;
        font-weight: 600;
        font-size: 1rem;
        background-color: #fff;
        margin: 0rem 1rem;
    `
    const LinkWrap = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @media (max-width: ${devices.lg}) {
            display: none;
            > * {
                display: none;
            }
        }
    `

    const Hamburger = styled.span`
        font-size: 2rem;
        color: #000;
        display: none;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: ${devices.lg}) {
            display: flex;
        }
    `

    const Collaspe = styled.div`
        width: 100%;
        padding: 2rem 0rem;
        background: #fff;
        height: 10rem;
        animation: ${fadeDown} 0.75s ease ${isToggle ? '' : 'reverse'};
        display: ${isToggle ? 'block' : 'none'};
    `

    const CollaspeMenu = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
        > * {
            width: 80%;
            display: flex;
            justify-content: flex-start;
            transition: all 0.5s ease;
        }

        > *:hover {
            background: #f4f4f4;
        }
    `

    return (
        <NavBar>
            <Contents>
                <ItemWrap><Link href="/">LOGO</Link></ItemWrap>
                <ItemWrap>
                    <Hamburger onClick={toggleMenu}>
                        <span className="material-icons material-icons-outlined">menu</span>
                    </Hamburger>
                    <LinkWrap>
                        <Link href="/science"> Research Reference </Link>
                        <Link href="/report"> Report Issue </Link>
                        <SubscribeModal titleModal="Please Email Me" btnWidth="7.5rem" btnColor="#2ec4b6" btnTitleText="Contact Us"> </SubscribeModal>
                    </LinkWrap>
                </ItemWrap>
            </Contents>
            <Collaspe>
                <CollaspeMenu>
                    <Link href="/science"> Research Reference </Link>
                    <Link href="/report"> Report Issue </Link>
                    <SubscribeModal titleModal="Please Email Me" btnWidth="7.5rem" btnColor="#2ec4b6"  btnTitleText="Contact Us" > </SubscribeModal>
                </CollaspeMenu>
            </Collaspe>
        </NavBar>
    )
}
