import React from 'react'
import styled from 'styled-components'
import { devices } from '../styles/devices'



const Column = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
`

const SectionWrapper = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`

const TitleHead = styled.div`
    font-size:3rem;
    font-weight: 200;
    width:50%;
    text-align: center;
    @media (max-width: ${devices.md}) {
        width:100%;
        font-size:3rem;
    }
    @media (max-width: ${devices.sm}) {
        font-size:2.5rem;
    }
`

const ContactBackground = styled.div`
    width: 100%;
    background: #0f3f45;
    max-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
`

const FooterSection1 = styled.div` 
    display: flex;   
    flex-direction: row;
`

const FooterSection2 = styled.div` 
    display: flex;   
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 2px solid #f6bd60;
`

const Col =  styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;   
`;

const Footerblock = styled.div`
    flex:0.1 1 auto;
    padding: 30px 0;
`
export default function Footer() {
        return (
            <SectionWrapper>
               <ContactBackground>
                <Column>
                    <FooterSection1>
                        <Footerblock>Home</Footerblock>
                        <Footerblock>Research Reference</Footerblock>
                    </FooterSection1>
                    <FooterSection2>
                        <Col>
                        <Footerblock>
                             icon
                        </Footerblock>
                        </Col>
                        <Col>
                        <Footerblock style={{display:'flex',justifyContent:'flex-end'}}>
                         Bright Dolphins ©
                        </Footerblock>
                        </Col>
                    </FooterSection2>
                </Column>
                </ContactBackground>
            </SectionWrapper>
        )
}