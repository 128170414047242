import React, { useState } from "react";
import '../styles/modal.scss'
import ReactModal from "react-modal";
import styled from 'styled-components'
import { devices } from '../styles/devices'
import axios from 'axios';
import { navigate } from 'gatsby';

ReactModal.setAppElement('#___gatsby')

export default function SubscribeModal(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState((""));
  const [email, setEmail] = useState((""));
  const {titleModal,btnWidth,btnColor,btnBorder,btnTitleText} = props;
  
  const handleChange = event => {
    if(event.target.name === "name"){
      setName(event.target.value)
     }
     else if(event.target.name === "email"){
      setEmail(event.target.value)
     }
  }

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const sentSubscribe = event => {
    event.preventDefault();
    if(name === ""){
      alert("You must enter a valid name")
    }else if(email === ""){
       alert("You must enter a valid email address")
    }else{

        const myfullname =  name.split(" ");
        const fname = myfullname[0]
        const lname = myfullname[1]

      const url = 'https://api.sendgrid.com/v3/marketing/contacts'
      const token = process.env.GATSBY_MY_TOKEN
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        }
      }
      const contact = {
            "list_ids": [
                "17577248-ca25-488c-840f-ab764ff4e79c"
            ],
            "contacts": [
                {
                "email": email,
                "first_name": fname,
                "last_name":lname
                }
        ]}

        axios.put(url,contact,config).then(res=>{
          if(res.statusText==='Accepted'){
            alert("Thank you ! for subscribing")
            navigate("/")
          }else{
            alert("We're sorry. This email has already been used to subscribe to this campaign.")
          }
        })
    }
  }

  const InputStyle = styled.input`
    border: 2px solid black;
    min-height: 30px;
    width: 100%;
    padding: 10px 2px;
    margin: 10px 0px;
  `
  const ButtonStyle = styled.div`
    color: black;
    display: flex;
    padding: 16px 24px;
    background: ${(props) => props.color};
    white-space: nowrap;
    border-radius: 4px;
    width: ${(props) => props.width};
    justify-content:center;
    font-weight: 600;
    cursor: pointer;
    border: ${(props) => props.border};

    @media (max-width: ${devices.md}) {
        width: ${(props) => 'auto'};
    }
`
  return (


    <div className="subscribe-modal">
      <ButtonStyle onClick={toggleModal} border={btnBorder}
      padding="15px" color ={btnColor} width={btnWidth}> {btnTitleText}</ButtonStyle>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500} 
      >
        <div>
         <p style={{fontSize:'24px',margin:'10px 0'}}>{titleModal}</p>

         <form method="post">
            <InputStyle name="name" id="name" type="text"  onChange={handleChange} placeholder="Name *" required/>
            <InputStyle name="email" id="email" type="email" onChange={handleChange} placeholder="Email *" required/>
            <ButtonStyle onClick={sentSubscribe} color ="#2ec4b6" width="20%" borderRadius="5px">Get it now</ButtonStyle>
         </form>
        </div>
      </ReactModal>
    </div>
  );
}